/************* 自定义modal相关样式 ****************/
.custom_modal_mask {
    top: 0;
    left: 0;
    position: fixed;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
}

.custom_modal_win {
    background: #fff;
    position: relative;
    border-radius: 5px;
    min-width: 600px;
}

.custom_modal_header {
    width: 100%;
    height: 40px;
    line-height: 30px;
    cursor: move;
    text-align: left;
    padding: 5px 20px;
    border-bottom: 1px solid #e3e3e3;
}

.custom_modal_header_close {
    float: right;
    line-height: 30px;
    cursor: pointer;
}

.custom_modal_content {
    top: 0;
    width: 100%;
    bottom: 50px;
    overflow: auto;
    padding: 10px 20px;
    text-overflow: ellipsis;
    background-color: #fff;
    text-align: left;
    max-height: 80vh;
    min-height: 50px;
}

.custom_modal_footer {
    bottom: 0;
    width: 100%;
    height: 46px;
    background: #fff;
    border-top: 1px solid #e3e3e3;
    border-radius: 0 0 5px 5px;
}

.custom_modal_footer_inner {
    float: right;
    right: 8px;
    bottom: 8px;
    position: absolute;
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;
/* 地图搜索 */
.map-box{
    position: relative;
}
.container{
    width: 100%;
    height: 500px;
}
.panel{
    position: absolute;
    width: 300px;
    top: 30px;
    top: 100px;
    left: 30px;
    z-index: 9999;
}
 
.suggestionList{
    list-style: none;
    // padding: 0 5px 10px;
    margin: 0;
    background: #fff;
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 9999999;
    width: 100%;
    li{
        list-style: none;
        margin-top: 2px;
        background-color: #f6f6f6;
        text-decoration: none;
        font-size: 14px;
        color: black;
        display: block;
        cursor: pointer;
        padding:10px;
        .item_info {
            font-size: 12px;
            color: grey;
        }
    }
    li a:hover:not(.header) {
        background-color: #eee;
    }
}
@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;